@use 'sass:math';
@import '../../../../../styles/variables';

.unhab-DataLayersGroup-info {
	display: flex;
	margin-top: -20px;
	margin-right: 22px;
	width: 1.04rem;
	position: absolute;
	right: 12px;
}
