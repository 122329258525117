@use 'sass:math';
@import '../../../styles/variables';

// Container
.unhab-ChipsContainer {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.25rem;
  align-items: center;
}

// Chip
.unhab-Chip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 0.5rem;
  border: 1px solid var(--base35);
  border-radius: 1rem;

  &.without-tools {
      padding-right: 0.5rem;
  }
}

.unhab-Chip-content {
  padding: 0.2rem 0 0.15rem;
  display: flex;
}

.unhab-Chip-tools {
  display: flex;
}

.unhab-Chip-tools .ptr-button.small {
  padding: 0 0.05rem;
  border-radius: 1rem;
  margin-left: 0.15rem;
  min-height: 1.25rem;
}

.unhab-Chip-tools .ptr-button.small > .ptr-icon,
.unhab-Chip-tools .ptr-button.small > .ptr-react-icon {
  margin: 0.1rem;
}

.unhab-Chip-tools .ptr-button:after {
  border-radius: 1rem;
}