@use 'sass:math';
@import '../../../../../../styles/variables';

.unhab-TimeProgressChartLegend {
  display: flex;
  flex-wrap: wrap;
  padding: 0 .5rem;
  margin-top: .5rem;
}

.unhab-TimeProgressChartLegendItem {
  font-size: .75rem;
  color: $textColor_secondary;
  display: flex;
  grid-gap: .25rem;
  align-items: center;
  margin-right: .75rem;
}

.unhab-TimeProgressChartLegendItem-color {
  width: .7rem;
  height: .7rem;
  border-radius: .5rem;
}