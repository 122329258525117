@use 'sass:math';
@import '../../../styles/variables';

.unhab-MapForExport-map {
    overflow-y: auto;
    border: 2px solid var(--base90);
    position: relative;
}

.unhab-MapForExport-mapImage {
    display: block;
		width: 100%;
		height: 25rem;
}