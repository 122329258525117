@use 'sass:math';
@import '../../../styles/variables';

.unhab-DataFilter {
	height: 40rem;
	max-height: 90%;
	width: 60rem;
	max-width: 90%;
}

.unhab-DataFilter .ptr-Modal-content {
	width: 100%;
	height: 100%;
	display: flex;
	max-height: none;
}

.unhab-DataFilter .ptr-Modal-content > div {
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
}

.unhab-DataFilter-filter {
	width: 20rem;
	background: var(--base10);
	height: 100%;
	padding: 2rem 1rem;
}

.unhab-DataFilter-filter > h2 {
	margin-top: 0;
}

.unhab-DataFilter-filter-categories {
	display: flex;
	flex-direction: column;
	grid-gap: 1rem;
}

.unhab-DataFilter-filter-sdg {
	margin-top: 0.5rem;
	padding-top: 0.75rem;
	border-top: 1px solid $borderColor_secondary;
}

.unhab-DataFilter-filter-sdg .unhab-MenuToggleItem {
	margin: 0 -0.5rem;
	width: calc(100% + 1rem);
}

.unhab-DataFilter-content {
	flex: 1;
	min-width: 32rem;
	height: 100%;
	padding: 0;
	display: flex;
	flex-direction: column;
	position: relative;
}

.unhab-DataFilter-content:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 5px);
	height: 30px;
	background: linear-gradient(
		to bottom,
		rgba(var(--base0rgb), 1),
		rgba(var(--base0rgb), 0.8),
		rgba(var(--base0rgb), 0)
	);
}

.unhab-DataFilter-content-body {
	flex: 1;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 2rem;
}

.unhab-DataFilter-content-body > h3 {
	margin: 0 0 .5rem;
}

.unhab-DataFilter-content .ptr-FormFooter {
	padding: 0 2rem 1.5rem;
	position: relative;
}

.unhab-DataFilter-content .ptr-FormFooter:before {
	content: '';
	position: absolute;
	top: -40px;
	left: 0;
	width: calc(100% - 5px);
	height: 30px;
	background: linear-gradient(to top, rgba(var(--base0rgb), 1), rgba(var(--base0rgb), 0.8), rgba(var(--base0rgb), 0));
}
