@use 'sass:math';
@import '../../../styles/variables';

.unhab-MapForReport {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
}