@use 'sass:math';
@import '../../../../styles/variables';

.ptr-TimeRangeLabel {
	font-size: $b1;
	font-weight: bold;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	display: flex;
	align-items: center;
}

.ptr-TimeRangeLabel > .ptr-icon {
	margin-top: -2px;
	margin-right: 0.25rem;
}

.ptr-TimeRangeLabel > .ptr-icon .line {
	fill: var(--accent50);
}

.ptr-TimeRangeLabel > span {
	font-weight: bold;
	color: var(--accent50);
}

.ptr-TimeRangeLabel-divider {
	margin: 0 0.15rem;
}
