@use 'sass:math';
@import '../../../../../../styles/variables';

.unhab-DataLayersGroup {
	display: flex;
	flex-direction: column;
	grid-gap: 0.15rem;

	&.is-open > .unhab-DataLayersGroup-content {
		display: flex;
	}
	&.is-open > .unhab-DataLayersGroup-content > .unhab-DataLayersGroup {
		border-left: 1px solid $borderColor_secondary;
	}
	&.is-secondary {
		margin-left: 0.75rem;
	}
	&.is-open > .unhab-DataLayersGroup-header {
		color: $textColor_base;
	}

	&.is-secondary .unhab-DataLayersGroup-header {
		text-transform: none;
	}

	&.is-highlighted .unhab-DataLayersGroup-header {
		color: $textColor_accented;
	}

	&.is-large > .unhab-DataLayersGroup-header {
		font-size: 0.875rem;
		height: 1.5rem;
	}
}

.unhab-DataLayersGroup-header {
	font-size: 0.75rem;
	color: $textColor_base;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 1.25rem;
}

.unhab-DataLayersGroup.is-closeable .unhab-DataLayersGroup-header {
	cursor: pointer;
}

.unhab-DataLayersGroup-title {
	padding-top: 0.15rem;
	font-weight: bold;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: flex;
	flex-direction: row;
}

.unhab-DataLayersGroup-tags {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;
	margin-left: 0.25rem;
	text-transform: none;
}

.unhab-DataLayersGroup-control {
	height: 1.25rem;
	width: 1.15rem;
}

.unhab-DataLayersGroup-content {
	display: none;
	flex-direction: column;
	padding-bottom: 0.25rem;

	> .unhab-DataLayersGroup {
		margin-left: 0.5rem;
		padding-left: 0.5rem;
	}
}