@import '../../../styles/variables';

.ptr-Radio {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.ptr-Radio.is-disabled {
	pointer-events: none;
	color: var(--base35);
}

.ptr-Radio > span {
	margin-left: 0.25rem;
	color: var(--base90);
	line-height: 1;
	padding-top: 0.1rem;
}

.ptr-Radio input:checked {
	accent-color: var(--accent50);
}
