@use 'sass:math';
@import '../../../../../styles/variables';

.unhab-ReportSelections {
    padding: .5rem .75rem;
    background: var(--accent05);
    border: 1px solid var(--accent15);
    display: flex;
    flex-direction: column;
    grid-gap: .25rem;
    width: $reportColumnWidth;
}

.unhab-ReportSelections-row {
    display: flex;
    align-items: center;
    grid-gap: .5rem;
}

.unhab-ReportSelections-name {
    font-size: $b1;
    font-weight: 600;
    color: $textColor_base;
}

.unhab-ReportSelections-item .unhab-ChipsContainer .ptr-ColorDotLabel:not(:last-child):after {
    content: ",";
    margin-left: -.2rem;
}

.unhab-ReportSelections-item .unhab-PeriodSelector > span {
    color: $textColor_base;
}