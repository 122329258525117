@use 'sass:math';
@import '../../../../styles/variables';

.unhab-FocusControl {
  min-width: 10rem;
  display: flex;
  flex-direction: column;
  grid-gap: .15rem;
}

.unhab-FocusControlLevel {
  display: flex;
  grid-gap: .25rem;
  align-items: center;
}

.unhab-FocusControlLevel label {
  font-size: $b1;
  color: $textColor_base;
  padding-top: .1rem;
}