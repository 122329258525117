@use 'sass:math';
@import '../../../styles/variables';

.ptr-IndicatorBoxSet {
	display: none;
	border: 1px solid var(--base15);
	max-width: $reportColumnWidth;
}

.ptr-IndicatorBoxSet:has(div) {
	display: block;
}

.ptr-IndicatorBoxSet > .unhab-IndicatorBox {
	padding: 0.25rem 0.5rem;
}

.ptr-IndicatorBoxSet > .unhab-IndicatorBox:first-child {
	padding-top: 0.5rem;
}

.ptr-IndicatorBoxSet > .unhab-IndicatorBox:last-child {
	padding-bottom: 0.5rem;
}

.ptr-IndicatorBoxSet > .unhab-IndicatorBox:not(:last-child) {
	border-bottom: 1px solid var(--base15);
}

.ptr-IndicatorBoxSet .ptr-BoxTitle {
	font-size: $b1;
}

.unhab-IndicatorBox {
	padding: 0.5rem;
	max-width: $reportColumnWidth;
	break-after: auto;
	break-before: auto;
	border-radius: 0;
	margin-top: -1px;

	&.is-report {
		border: 1px solid var(--base20);
	}
}

.unhab-IndicatorBoxHeader {
	display: flex;
	width: 100%;
	grid-gap: 0.75rem;
}

// Header
.unhab-IndicatorBoxHeader-title {
	display: flex;
	grid-gap: 0.15rem;
	flex: 1;
}

.unhab-IndicatorBoxHeaderItem.unhab-IndicatorBoxItem {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	grid-gap: 0.1rem;
	align-self: normal;
}

.unhab-IndicatorBoxHeaderItem-name {
	color: $textColor_tertiary;
	font-size: $b1;
}

.unhab-IndicatorBoxHeaderItem > .ptr-icon,
.unhab-IndicatorBoxHeaderItem > .ptr-react-icon {
	color: $textColor_tertiary;
	fill: $textColor_tertiary;
	width: 0.9rem;
	height: 0.9rem;
}

// Body
.unhab-IndicatorBoxBody {
	display: flex;
	flex-direction: column;
}

.unhab-IndicatorBoxBodyRow {
	display: flex;
	flex-direction: column;
}

.unhab-IndicatorBoxBodyRow-main {
	display: flex;
	align-items: center;
	grid-gap: 0.75rem;
}

.unhab-IndicatorBoxBodyRowDetail {
	display: flex;
	flex-direction: column;
}

.unhab-IndicatorBoxBodyRowDetail-row {
	display: flex;
	align-items: center;
	grid-gap: 0.75rem;
}

// Items
.unhab-IndicatorBoxItem {
	justify-content: flex-end;
	display: flex;
	align-self: baseline;
}

.unhab-IndicatorBoxItem.is-secondary {
	font-size: $a0;
	color: $textColor_tertiary;
	font-weight: normal;
}

.unhab-IndicatorBoxItem.period {
	width: 5rem;
}

.unhab-IndicatorBoxItem.absolute {
	width: 7rem;
}

.unhab-IndicatorBoxItem.absolute:nth-child(2n) {
	width: 3.5rem;
}

.unhab-IndicatorBoxItem.relative {
	width: 3.5rem;
}

.unhab-IndicatorBoxNames {
	flex: 1;
}

.unhab-IndicatorBoxBodyRow:has(.ptr-ColorDotLabel:last-child:not(:first-child)) {
	margin-bottom: 0.5rem;
}

.unhab-IndicatorBoxItem {
	color: $textColor_base;
	font-size: $b1;
	font-weight: bold;
}

.unhab-IndicatorBoxValues {
	display: flex;
	grid-gap: 0.5rem;
}

.unhab-IndicatorBoxValue {
	display: flex;
	grid-gap: 0.15rem;
	min-width: 3.5rem;
	justify-content: flex-end;
	align-items: baseline;
}

.unhab-IndicatorBoxValue > div {
	color: $textColor_base;
	font-weight: bold;
	font-size: $a1;
	line-height: 1;
}

.unhab-IndicatorBoxValue.is-secondary > div {
	font-size: $b1;
}

.unhab-IndicatorBoxValue > span,
.unhab-IndicatorBoxValue > div > span {
	color: $textColor_secondary;
	font-size: $b1;
}

.unhab-IndicatorBoxValue.is-secondary > span,
.unhab-IndicatorBoxValue.is-secondary > div > span {
	color: $textColor_tertiary;
	font-size: $a0;
}
