@use 'sass:math';
@import '../../../../../../../styles/variables';

.ptr-LayerSettingsItem {
	display: flex;
	height: 1.75rem;
	font-size: $b1;
	align-items: center;
	color: $textColor_secondary;
	border-top: 1px solid $borderColor_secondary;
	text-decoration: none;
	padding: 0 0.5rem;
	width: 100%;
	justify-content: space-between;

	//&:last-child {
	//	border-bottom: 1px solid $borderColor_secondary;
	//}
}

.ptr-LayerSettingsItem.is-hoverable {
	cursor: pointer;
}

.ptr-LayerSettingsItem.is-hoverable:hover,
a.ptr-LayerSettingsItem.is-hoverable:hover {
	background: $hoverBackgroundColor;
	color: $textColor_base;
}

.ptr-LayerSettingsItem.is-disabled {
	color: var(--base30);
	pointer-events: none;
}

.ptr-LayerSettingsItem-title {
}

.ptr-LayerSettingsItem-control {
	height: 100%;
	display: flex;
	align-items: center;
	margin-left: 2rem;
	justify-content: flex-end;
}
