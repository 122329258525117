@use 'sass:math';
@import '../../../../../styles/variables';

.unhab-MultiAttributesChart {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.unhab-MultiAttributesChart-content {
  display: flex;
  width: 100%;
  height: 10rem;
}

.unhab-MultiAttributesChart-content > div {
  width: 100%;
  height: 100%;
  position: relative;
  min-width: 7rem;
  min-height: 9.5rem;
}

.unhab-ChartTooltip {
  background: var(--base100);
  padding: .5rem;
  box-shadow: $floatingBoxShadow;
  border-radius: .15rem;
}

.unhab-ChartTooltip-content {
  display: flex;
  flex-direction: column;
}

.unhab-ChartTooltip-title {
  display: flex;
  grid-gap: .25rem;
  font-size: $b1;
  align-items: center;
  font-weight: bold;
}

.unhab-ChartTooltip-name {
  font-weight: bold;
  color: var(--base0);
}

.unhab-ChartTooltip-color {
  width: .7rem;
  height: .7rem;
  border-radius: 100%;
}

.unhab-ChartTooltip-attribute {
  display: flex;
  grid-gap: .25rem;
  font-size: $b1;
  color: var(--base0);
}

.unhab-ChartTooltip-attributeName {
  color: var(--base30);
}