@use 'sass:math';
@import '../../../../../styles/variables';

.unhab-ReportMap {
    width: 100%;
}

.unhab-ReportMap-mapWindow {
	width: 100%;
	height: 25rem;
}

.unhab-ReportMap .ptr-map-grid-cell {
    overflow: visible;
}

.unhab-ReportMap-MapLabels {
	display: flex;
	flex-direction: row;
	margin-bottom: 0.5rem;
	gap: 0.5rem;
	justify-content: flex-end;
}

.unhab-ReportMap-MapInfo {
	display: flex;
	flex-direction: row;
	margin-top: 0.5rem;
	gap: 0.5rem;
}