@use 'sass:math';
@import '../../../../styles/variables';

.unhab-FilteredDatasetList {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
}

.unhab-FilteredDatasetListItem {
  display: flex;
  align-items: center;
  grid-gap: .35rem;
  line-height: 1.5rem;
}

.unhab-FilteredDatasetListItem-name {
  color: $textColor_base;
  font-size: $b1;
}