@use 'sass:math';
@import '../../../../../styles/variables';

.unhab-AreaShareChart {
  height: 10rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.unhab-AreaShareChart-content {
  display: flex;
  width: 100%;
  height: calc(100% - 1.5rem);
}

.unhab-AreaShareChart-content > div {
  width: 100%;
  height: 100%;
  min-width: 7rem;
  min-height: 7rem;
}

.unhab-AreaShareChart-legend {
  display: flex;
  grid-gap: 1rem;
  justify-content: center;
}

.unhab-AreaShareChart-legendItem {
  display: flex;
  grid-gap: .35rem;
  align-items: center;
}

.unhab-AreaShareChart-legendItemColor {
  min-width: .7rem;
  height: .7rem;
  border-radius: .35rem;
}

.unhab-AreaShareChart-legendItemName {
  font-size: $b1;
  color: $textColor_secondary;
}