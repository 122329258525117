@use 'sass:math';
@import '../../../styles/variables';

$width: 1.7rem;
$height: 1rem;
$borderWidth: 0.05rem;

.unhab-Toggle {
	position: relative;
	width: $width;
	height: $height;
	border-radius: math.div($height, 2);
	cursor: pointer;
	border: $borderWidth solid $borderColor_base;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.unhab-Toggle-bullet {
	background: $iconColor_secondary;
	width: $height - $borderWidth * 6;
	height: $height - $borderWidth * 6;
	border-radius: math.div($height, 2);
	position: absolute;
	left: 2*$borderWidth;
	top: 2*$borderWidth;
	transition: left 0.15s ease-in-out, background-color 0.15s ease-in-out;
}

// on
.unhab-Toggle.is-on {
	background: var(--accent10);
	border: $borderWidth solid var(--accent50);
}

.unhab-Toggle.is-on .unhab-Toggle-bullet {
	left: $width - $height + $borderWidth;
	background: $iconColor_accented;
}

// disabled
.unhab-Toggle.is-disabled {
	border-color: var(--base25);
	pointer-events: none;
}
.unhab-Toggle.is-on.is-disabled {
	border-color: var(--accent25);
}

.unhab-Toggle.is-disabled .unhab-Toggle-bullet {
	background: var(--base25);
}
.unhab-Toggle.is-on.is-disabled .unhab-Toggle-bullet {
	background: var(--accent25);
}
