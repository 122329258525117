@use 'sass:math';
@import '../../../styles/variables';

.unhab-AppOptions {
  position: relative;
}

.unhab-AppOptions-menu {
  top: 2.5rem;
  left: auto;
  right: .5rem;
  min-width: 10rem;
}