@use 'sass:math';
@import '../../../../../styles/variables';

.unhab-EditableText {
    display: flex;
    align-items: center;
    max-width: $reportColumnWidth;
}

.unhab-EditableText > input,
.unhab-EditableText > textarea {
    border: none;
    background: var(--base05);
    padding: .25rem 2rem .25rem .25rem;
    border-bottom: 2px dotted $borderColor_secondary;
    flex: 1;
}

.unhab-ReportPreview.is-print-in-progress .unhab-EditableText > input,
.unhab-ReportPreview.is-print-in-progress .unhab-EditableText > textarea {
    border-bottom-width: 0;
    background: none;
}

.unhab-EditableText > input::placeholder,
.unhab-EditableText > textarea::placeholder {
    color: $textColor_tertiary;
    font-weight: normal;
}

.unhab-EditableText > .ptr-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: -1.5rem;
    pointer-events: none;
}

.unhab-ReportPreview.is-print-in-progress .unhab-EditableText > .ptr-icon {
    display: none;
}

.unhab-ReportTitle {
    padding-bottom: .5rem;
}

.unhab-ReportTitle > input {
    font-size: 1.45rem;
    font-weight: bold;
    color: $textColor_base;
    margin-top: .25rem;
}

@media print {
    .uhab-EditableText > input,
    .uhab-EditableText > textarea {
        border-bottom-width: 0;
        background: none;
        padding: 0;
    }

    .unhab-EditableText > .ptr-icon {
        display: none;
    }
}