@use 'sass:math';
@import '../../../styles/variables';

.unhab-MethodSelect {
	display: flex;
	flex-direction: column;
}

.unhab-MethodSelect > .ptr-Select {
	min-width: 15rem;
}

.unhab-MethodSelect-option {
	display: flex;
	align-items: center;
	grid-gap: 0.5rem;
}

.unhab-MethodSelect-option-label {
	padding-top: 2px;
	color: $textColor_base;
	font-weight: 500;
}
