@use 'sass:math';
@import '../../../styles/variables';

.ptr-ColorDotLabel {
  display: flex;
  grid-gap: .25rem;
  align-items: center;

  &.is-clickable {
    cursor: pointer;
  }
}

.ptr-ColorDotLabel-name {
  font-size: $b1;
  line-height: 1rem;
  color: $textColor_base;
  padding-top: .05rem;
  display: flex;
  align-items: center;
}

.ptr-ColorDotLabel.is-large .ptr-ColorDotLabel-name {
  font-size: $a1;
  font-weight: bold;
  padding-top: 0;
  line-height: 1.5;
}

.ptr-ColorDotLabel-name > span {
  color: $textColor_tertiary;
  font-size: $b1;
  margin-left: .2rem;
}