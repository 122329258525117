@use 'sass:math';
@import '../../../../../../styles/variables';

.unhab-ComparativeChartLegend {
  display: flex;
  flex-wrap: wrap;
	gap: .5rem;
}

.unhab-ComparativeChartLegend-section {
	display: flex;
	flex-direction: row;
}

.unhab-ComparativeChartLegendItem {
  font-size: .75rem;
  color: $textColor_secondary;
  display: flex;
  grid-gap: .25rem;
  align-items: center;
  margin-right: .5rem;
}

.unhab-ComparativeChartLegendItem-color {
  width: .7rem;
  height: .7rem;
}