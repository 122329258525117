@use 'sass:math';
@import '../../../styles/variables';

.unhab-FormatGroupLabel {
  color: $textColor_secondary;
  margin-left: -.25rem;
  display: flex;
  grid-gap: .5rem;
  align-items: center;

  > span {
    font-weight: bold;
  }

  &.with-border {
    border-top: 1px solid $borderColor_secondary;
    padding-top: .5rem;
  }
}