@use 'sass:math';
@import '../../../../styles/variables';

.unhab-GeonamesSearchBox {

}

.unhab-GeonamesSearchBox.ptr-GeonamesSearch > div > .wrapper {
  box-shadow: $floatingBoxShadow;
  border-radius: .25rem;
  border: 0;
}

.unhab-GeonamesSearchBox.ptr-GeonamesSearch > div > .wrapper > div:first-child,
.unhab-GeonamesSearchBox.ptr-GeonamesSearch > div > .wrapper > div > input {
  min-height: 2rem;
  height: 2rem;
}

.unhab-GeonamesSearchBox.ptr-GeonamesSearch > div > .wrapper > div > input {
  font-size: $b1;
  margin-left: .2rem;
}

.unhab-GeonamesSearchBox.ptr-GeonamesSearch > div > .wrapper .line {
  padding-bottom: 0;
}
