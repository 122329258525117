@use 'sass:math';
@import '../../../../styles/variables';

.unhab-MenuToggleItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0.5rem;
    width: 100%;
}

.unhab-MenuToggleItem:hover {
    background-color: $hoverBackgroundColor;
}

.unhab-MenuToggleItem.is-disabled {
    pointer-events: none;
}

.unhab-MenuToggleItem > .ptr-icon,
.unhab-MenuToggleItem > .ptr-react-icon  {
    width: 1.15rem;
    height: 1.15rem;
    margin-right: .35rem;
}

.unhab-MenuToggleItem > .ptr-icon {
    fill: $iconColor_secondary;
}

.unhab-MenuToggleItem > .ptr-react-icon {
    color: $iconColor_secondary;
}

.unhab-MenuToggleItem.is-disabled > .ptr-icon {
    fill: $iconColor_disabled;
}

.unhab-MenuToggleItem.is-disabled > .ptr-react-icon,
.unhab-MenuToggleItem.is-disabled .unhab-MenuToggleItem-content {
    color: $iconColor_disabled;
}

.unhab-MenuToggleItem-content {
    flex: 1;
    font-size: $b1;
    margin-right: 1rem;
    white-space: nowrap;
    color: $textColor_base;
}

.unhab-MenuToggleItem.is-large .unhab-MenuToggleItem-content {
    font-size: $a1;
}