@use 'sass:math';
@import '../../../styles/variables';

.ptr-Form {
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;

  &.is-small {
    grid-gap: .5rem;
  }

  &.is-small .ptr-FormItemLabel {
    font-weight: 400;
  }

  &.is-small .ptr-FormItem {
    max-width: 20rem;
  }

  &.is-small .ptr-FormFooter {
    margin-top: .25rem;
    grid-gap: .75rem;
  }
}

.ptr-FormItem {
  display: flex;
  flex-direction: column;

  &.is-condensed {
    flex-direction: row;
    align-items: center;

    .ptr-FormItemLabel {
      text-transform: none;
      margin-right: .5rem;
    }

    .ptr-FormItemLabel:after {
      content: ":";
      margin-left: -.2rem;
    }
  }
}

.ptr-FormItemLabel {
  font-size: .75rem;
  font-weight: 500;
  text-transform: uppercase;
  color: $textColor_secondary;
  display: flex;
  grid-gap: .25rem;

  .ptr-react-icon {
    width: 1rem;
    height: 1rem;
    color: $iconColor_secondary;
  }
}

.ptr-FormFooter {
  margin-top: .75rem;
  display: flex;
  grid-gap: 1rem;
}