@use 'sass:math';
@import '../../../styles/variables';

.esaUtepUnHabitat .unhab-SmallCircularButton.ptr-button.circular {
	width: 1.5rem;
	height: 1.5rem;
    background: var(--base20);
    margin: 0 .15rem;
}

.esaUtepUnHabitat .unhab-SmallCircularButton.ptr-button.circular:hover {
    background: var(--base25);
}

.esaUtepUnHabitat .unhab-SmallCircularButton.ptr-button.circular.change {
    padding: .1rem;
}

.esaUtepUnHabitat .unhab-SmallCircularButton.ptr-button.circular.change > .ptr-react-icon {
    width: .8rem;
    height: .8rem;
}

.esaUtepUnHabitat .unhab-SmallCircularButton.ptr-button.circular > .ptr-icon,
.esaUtepUnHabitat .unhab-SmallCircularButton.ptr-button.circular > .ptr-react-icon {
	margin: 0.22rem;
}
