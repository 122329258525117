@use 'sass:math';
@import '../../../styles/variables';

.unhab-DatasetSelect {
	display: flex;
}

.unhab-DatasetSelect > .ptr-Select {
	min-width: 30rem;
}

.unhab-DatasetSelect-option {
	display: flex;
	align-items: center;
	grid-gap: 0.5rem;
}

.unhab-DatasetSelect-option-label {
	padding-top: 2px;
	color: $textColor_base;
	font-weight: 500;
}
