@use 'sass:math';
@import '../../../styles/variables';

.ptr-Popover {
  display: none;
  position: absolute;
  top: 2rem;
  left: 0;
  background: var(--base05);
  border-radius: .25rem;
  border: 1px solid $borderColor_secondary;
  box-shadow: $floatingBoxShadow;
  z-index: 2;
  overflow-y: auto;
  overflow-x: hidden;

  &.is-open {
    display: block;
  }

  &.show-top {
    bottom: 2rem;
    top: auto;
  }
}