@use 'sass:math';
@import '../../../../styles/variables';

.unhab-StatisticsSection {
	display: flex;
	flex-direction: column;
	grid-gap: 0.15rem;
	border-bottom: 1px dashed $borderColor_secondary;
	padding: 0.15rem 1rem;
	margin: 0 -1rem;

	&.is-open .unhab-StatisticsSection-content {
		display: flex;
		padding-bottom: 0.5rem;
	}

	&.is-open {
		padding: 0.5rem 1rem;
	}

	&.is-open:last-child {
		border-bottom: 0;
	}
}

.unhab-StatisticsSection-header {
	font-size: $b1;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.1rem 0;
	transition: padding-top 0.15s ease-in-out;

	&:hover .unhab-StatisticsSection-title,
	&:hover .unhab-StatisticsSection-control {
		color: $textColor_accented;
	}
}

.unhab-StatisticsSection-title {
	font-weight: bold;
	color: $textColor_base;
	margin: 0;
}

.unhab-StatisticsSection-right {
	display: flex;
}

.unhab-StatisticsSection-info {
	margin-top: 1px;
	margin-right: 2px;
}

.unhab-StatisticsSection-control {
	height: 1.25rem;
	width: 1.15rem;
	color: $iconColor_base;
}

.unhab-StatisticsSection-content {
	display: none;
	flex-direction: column;
	padding: 0;
	grid-gap: 0.5rem;
}
