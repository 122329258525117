@use 'sass:math';
@import '../../../../../styles/variables';

.unhab-MapTooltip-title {
	font-size: $a1;
	font-weight: bold;
	display: flex;
	grid-gap: .25rem;
	align-items: baseline;
}

.unhab-MapTooltip-subtitle {
	font-size: $b1;
	color: $textColor_secondary;
}

.unhab-MapTooltip-attributeSet {
	font-size: $b1;
	margin: 0.5rem 0;
	font-weight: bold;
	display: flex;
	grid-gap: .25rem;
}

.unhab-MapTooltip-attributeSet > span {
	color: $textColor_secondary;
}

.unhab-MapTooltip-values:not(:last-child) {
	margin-bottom: .5rem
}

.unhab-MapTooltip-values-title {
	font-size: $b1;
	color: $textColor_secondary;
}

.unhab-MapTooltip-values-content {
	display: flex;
	grid-gap: .5rem;
	align-items: baseline;
}
