@use 'sass:math';
@import '../../../styles/variables';

.ptr-TimeRangeChart {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
}
