@import '../../../../styles/variables';

.ptr-MapAttribution {
	z-index: 2;
	position: relative;
	font-size: $a0;
	color: var(--base50);
	padding: 0.1rem 0;
}

.ptr-MapAttribution a,
.ptr-MapAttribution a:visited {
	color: var(--base50);
}
