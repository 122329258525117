@use 'sass:math';
@import '../../../styles/variables';

.ptr-Tag {
  background: var(--base20);
  border: 1px solid var(--base25);
  color: $textColor_base;
  font-size: .6875rem;
  padding: 1px .5rem 0;
  border-radius: 1rem;
  display: flex;
  height: 1.25rem;
  align-items: center;

  &.is-small {
    padding: 1px .35rem 0;
    height: 1.05rem;
  }
}

.ptr-Tag-title {
  font-weight: bold;
  margin-right: .25rem;
}