@use 'sass:math';
@import '../../../styles/variables';

.unhab-AddAreaDialog {
	display: flex;
	padding: 1.5rem 2rem 2rem 1.5rem;
	flex-direction: column;
}

.unhab-AddAreaDialog > h2 {
	margin-top: 0;
}