@import '../../../styles/variables';

.ptr-Checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ptr-Checkbox.is-disabled {
  pointer-events: none;
  color: var(--base35);
}

.ptr-Checkbox > span {
  margin-left: .25rem;
  color: var(--base90);
  line-height: 1;
  padding-top: .1rem;
}

.ptr-Checkbox input:checked {
  accent-color: var(--accent50);
}