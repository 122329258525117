@use 'sass:math';
@import '../../../../styles/variables';

.unhab-HeaderTools {
  display: flex;
}

.unhab-HeaderToolsGroup {
  display: flex;
  padding: 0 .5rem;
}

.unhab-HeaderToolsGroup:not(:first-child) {
  border-left: 1px solid $borderColor_secondary;
}

.unhab-HeaderToolsGroup:last-child {
  padding-right: 0;
}