@use 'sass:math';
@import '../../../styles/variables';

.unhab-Map {
	flex: 1;
	height: 100%;
}

// Map set
.unhab-Map .ptr-map-set {
	position: relative;
	z-index: 2;
	margin-top: -$mapBorderWidth;
	height: calc(100% + $mapBorderWidth);
}

.unhab-Map.is-horizontal .ptr-map-grid {
	flex-direction: column;

	> .ptr-map-grid-cell {
		width: 100% !important;
		height: 50% !important;
	}
}

.unhab-Map .ptr-map-grid .ptr-map-grid-cell .ptr-map {
	border: 0;
}

.unhab-Map .ptr-map-grid .ptr-map-grid-cell:not(.row1) .ptr-map {
	border-top: $mapBorder;
}

.unhab-Map .ptr-map-grid .ptr-map-grid-cell:not(.col1) .ptr-map {
	border-left: $mapBorder;
}

.ptr-map-controls-wrapper {
	z-index: auto;
}

// Map set controls
.map-compare-tools-wrapper {
	position: absolute;
	z-index: 9;
	display: flex;
	flex-direction: column;
	align-items: center;
	top: $mapWindowPadding_top;
	width: 8rem;
	left: calc(50% - 4rem);

	&.is-horizontal {
		left: auto;
		right: $mapWindowPadding_right;
		align-items: flex-end;
	}
}


.unhab-CompareMapsControl-wrapper,
.unhab-KeepScaleControl-wrapper,
.unhab-LockMapsControl-wrapper{
	position: relative;
}

.unhab-CompareMapsControl-wrapper:not(:empty),
.unhab-KeepScaleControl-wrapper:not(:empty),
.unhab-LockMapsControl-wrapper:not(:empty){
	margin-bottom: .5rem;
}

.unhab-CompareMapsControl-wrapper {
	&.is-condensed >.ptr-button {
		width: 2.1rem;
	}
}

.unhab-LockMapsControl-wrapper {
	&.is-condensed >.ptr-button {
		width: 2.1rem;
	}
}

.unhab-KeepScaleControl-wrapper {
	&.is-condensed >.ptr-button {
		width: 2.1rem;
	}
}

// Map slider
.unhab-CompareSlider {
	margin-top: -$mapBorderWidth;
	height: calc(100% + $mapBorderWidth);
}

div[data-rcs='handle-container'] {
	z-index: 29;

	.ptr-IconTool {
		bottom: 40%;
	}
}

div[data-rcs='clip-item'] {
	z-index: 28;
}

.ptr-map-controls-wrapper {
	z-index: auto;
}
