@use 'sass:math';
@import '../../../../../styles/variables';


.unhab-ControlAllSections {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 0.5rem;
	width: 100%;
	cursor: pointer;
}

.unhab-ControlAllSections-divider {
	width: 100%;
	height: 1px;
	background-color: var(--accent50);
}

.unhab-ControlAllSections-control {
  float: right;
	height: 1.25rem;
	width: 1.15rem;
	color: var(--accent50);
}