@use 'sass:math';
@import '../../../../../../styles/variables';

.unhab-MapLabels-layers {
	display: flex;
	flex-direction: column;
	width: 50%;
}

.unhab-MapLabels-layersLabel {
	font-size: 0.9rem;
}

.unhab-MapLabels-layersLabelDetail {
	font-size: 0.75rem;
}