@import '../../../../styles/variables';

.unhab-mapWrapper {
	position: relative;
}

.ptr-map-wrapper.unhab-mapWrapper.active::after {
	border: 0;
	z-index: 1;
}

.ptr-map-wrapper.unhab-mapWrapper.one-map.active:after,
.ptr-map-wrapper.unhab-mapWrapper.not-highlight-active.active:after {
	border: 0;
}
