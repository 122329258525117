@use 'sass:math';
@import '../../../styles/variables';

.unhab-DatasetSetSelector {
	display: flex;
	grid-gap: 0.75rem;
	flex-wrap: wrap;
}

.unhab-DatasetSetSelectorItem {
	border: 1px solid $borderColor_base;
	border-radius: 0.25rem;
	cursor: pointer;
	padding: 0.5rem 0.75rem;
	position: relative;

	&.is-active {
		background: var(--accent05);
		border-color: var(--accent50);
		border-width: 1px;

		.unhab-DatasetSetSelectorItem-icon .ptr-react-icon {
			display: block;
		}

		.unhab-DatasetItem {
			color: var(--accent60);
			font-weight: bold;
		}
	}

	&:hover {
		background: $hoverBackgroundColor;
	}
}

.unhab-DatasetSetSelectorItem-icon {
	color: $iconColor_inverted;
	position: absolute;
	top: -0.35rem;
	right: -0.35rem;

	.ptr-react-icon {
		display: none;
		width: 1rem;
		height: 1rem;
		border-radius: 0.75rem;
		background: var(--accent50);
		padding: 0.1rem;
		border: 1px solid var(--base05);
	}
}

.unhab-DatasetItem {
	font-size: $a1;
	color: $textColor_base;
}
