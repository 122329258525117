@use 'sass:math';
@import '../../../../../../styles/variables';

.unhab-MapInfo-elements {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.unhab-MapInfo-elementsGroup {
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	align-items: center;
}

.unhab-MapInfo-elementsGroup .ptr-MapAttribution {
	font-size: 0.6rem;
}