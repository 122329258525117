@use 'sass:math';
@import '../../../styles/variables';

.unhab-ChangeAreaDialog {
	display: flex;
	padding: 1.5rem 2rem 2rem 1.5rem;
	flex-direction: column;
}

.unhab-ChangeAreaDialog > h2 {
	margin-top: 0;
}

.unhab-ChangeAreaDialog-form-row {
	display: flex;
	grid-gap: 2rem;
}

.unhab-ChangeAreaDialog-form-column {
	display: flex;
	flex-direction: column;
	grid-gap: 1rem;
	flex: 1;
	min-width: 13rem;
}