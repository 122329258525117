@use 'sass:math';
@import '../../../styles/variables';

.ptr-Box {
  background: var(--base0);
  padding: .5rem;
  border-radius: .15rem;
}
.ptr-BoxTitle {
  font-size: .9rem;
  font-weight: bold;
  color: $textColor_base;
  padding-top: .05rem;
  display: flex;
  align-items: baseline;
  grid-gap: .25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.is-active {
    cursor: pointer;
  }
}

.ptr-BoxSubtitle  {
  color: $textColor_tertiary;
  font-size: $b1;
}