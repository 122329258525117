@use 'sass:math';
@import '../../../../styles/variables';

.ptr-MapLegend {
  display: flex;
  flex-direction: column;
  grid-gap: .75rem;
  width: 20rem;
  max-width: 100%;
  font-size: $b1;
  padding: .25rem;
}