@use 'sass:math';
@import '../../../styles/variables';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;700&display=swap');

a {
	text-decoration: none;
}

.insar-Intro {
	position: relative;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-image: url('./images/background.jpg'); /* Background image */
	background-size: cover;
	background-position: center;
	overflow: hidden;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(27, 42, 69, 0.756); /* Color overlay with opacity */
	z-index: 0;
}

.content {
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 5%;
}

/* Page title styles */
.page-title {
	text-align: center;
	margin-bottom: 30px;
	font-family: 'Poppins', 'Raleway', 'Roboto', sans-serif;
}

.page-title h1 {
	font-size: $b4;
	margin: 0;
	font-weight: bold;
	color: $darkBase100;
}

.page-title p {
	font-size: 24px;
	color: $darkBase80;
	margin: 10px auto;
	font-weight: 300;
	float: left;
}

/* Cards */
.card-group {
	display: flex;
	justify-content: center;
	gap: 20px; /* Space between cards */
	width: 100%;
	max-width: 1200px; /* Limit the total width of the card group */
	margin: 0 auto; /* Ensure it's centered on larger screens */
}

.card-box {
	background-color: none; /* Button-like background color */
	padding: 5px 15px;
	border: 1px solid rgba($color: $lightAccent0, $alpha: 0.4);
	text-align: center;
	width: 250px; /* Set a fixed width for each button */
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	cursor: pointer;
	transition: all 0.3s ease; /* Smooth transition for hover effects */
	overflow: hidden;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Slight shadow for a button-like appearance */
	max-width: 800px;
	font-family: 'Poppins', 'Raleway', 'Roboto', sans-serif;
}

.card-box:hover {
	background-color: rgba(255, 255, 255, 0.3);
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.card-title {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 10px;
	color: $lightAccent0;
	margin-top: 5px;
	margin-bottom: 2px;
}

.card-subtitle {
	color: $darkBase80;
	font-size: $a1;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 5px;
}

/* Modal styles */
.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
}

.modal {
	background-color: $lightAccent0;
	padding: 20px 18px;
	border-radius: 3px;
	width: 580px;
	position: absolute;
	top: 48px;
	right: 48px;
	background: rgba($color: #202743, $alpha: 0.95);

	p {
		color: $lightAccent0;
		font-size: 16px;
	}

	h3 {
		font-size: 16px;
		color: $lightAccent0;
		margin-bottom: 0;
	}
	h4 {
		font-size: 16px;
		color: $lightAccent0;
		margin-top: 8px;
		margin-bottom: 30px;
	}
}

.close-button {
	position: absolute;
	top: 18px;
	right: 18px;
	width: 20px;
	height: 20px;
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.close-button::before,
.close-button::after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 18px; /* Length of the lines */
	height: 2px; /* Thickness of the lines */
	background-color: $lightAccent0; /* Line color */
	transform-origin: center;
	transition: background-color 0.3s ease; /* Smooth color transition */
}

.close-button::before {
	transform: translate(-50%, -50%) rotate(45deg); /* Diagonal line */
}

.close-button::after {
	transform: translate(-50%, -50%) rotate(-45deg); /* Diagonal line */
}

.close-button:hover::before,
.close-button:hover::after {
	background-color: $lightAccent20; /* Change color on hover */
}

/* Additional styles for responsiveness */
@media (max-width: 768px) {
	.card-group {
		flex-direction: column; /* Stack cards vertically on smaller screens */
		align-items: center;
		width: 100%;
	}

	.card-box {
		width: 90%; /* Cards will take 80% width on smaller screens */
		height: auto;
	}

	.page-title h1 {
		font-size: $a2;
	}

	.page-title p {
		font-size: $b2;
	}

	.page-title {
		margin-bottom: 0;
	}

	.page-button {
		display: flex;
		justify-content: center;
	}

	.modal {
		padding: 50px 80px;
		border-radius: 3px;
		width: 100%;
		height: 100vh;
		position: absolute;
		top: 0;
		right: 0;
	}
}

.page-button {
	width: 100%;
	margin-top: 40px;
	a {
		background-color: $lightAccent50;
		color: $lightAccent05 !important;
		padding: 16px 24px;
		text-transform: uppercase;
		transition: all 0.3s ease;
		font-family: Arial, Helvetica, sans-serif;
		font-size: $a1;
		font-weight: bold;
	}

	a:hover {
		color: $lightAccent0 !important;
		background-color: $lightAccent40;
	}
}

/* Footer for partner logos */
.footer {
	position: absolute;
	bottom: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	z-index: 1;

	:nth-child(5) {
		height: 50px;
	}
}

.partner-logo {
	width: auto; /* Let the width adjust based on the height */
	height: 35px; /* Set a consistent height */
	object-fit: contain; /* Ensure the logo fits within the defined height without being stretched */
	margin: 0 10px; /* Add some space between logos */
}

/* Info button styles */
.info-button {
	position: absolute;
	top: 20px;
	right: 20px;
	border: 2px solid rgba($color: $lightAccent0, $alpha: 0.5);
	width: 26px;
	background: none;
	color: $lightAccent0;
	height: 26px;
	border-radius: 50%;
	font-size: 16px;
	text-align: center;
	cursor: pointer;
	z-index: 1;
}
