@use 'sass:math';
@import '../../../../styles/variables';

.unhab-MapCoordinatesInfo {
  background: var(--base0);
  border-radius: .25rem;
  padding: .25rem .5rem .25rem;
  font-size: $b1;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  color: $textColor_base;
  box-shadow: $floatingBoxShadow;
}

.unhab-MapCoordinatesInfo-coordinates {
  display: flex;
  align-items: center;
}

.unhab-MapCoordinatesInfo-coordinates-text {
  margin-top: .15rem;
}

.unhab-MapCoordinatesInfo-close {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.unhab-MapCoordinatesInfo .ptr-icon {
  width: 1rem;
  height: 1rem;
  margin-right: .25rem;

  > g {
    fill: $iconColor_base;
  }
}

.unhab-MapCoordinatesInfo-close > .ptr-react-icon {
  width: 1rem;
  height: 1rem;

  &:hover {
    color: $textColor_secondary;
  }
}

.unhab-MapCoordinatesInfo-placeholder {
  font-style: italic;
}