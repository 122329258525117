@use 'sass:math';
@import '../../../../styles/variables';

.ptr-MapConfigurationControl {
  position: relative;
}

// Header
.ptr-MapConfigurationControl-header {
  display: flex;
  align-items: center;
}

.ptr-MapConfigurationControl.is-right .ptr-MapConfigurationControl-header {
  flex-direction: row-reverse;
}

.ptr-MapConfigurationControl-header > .ptr-button {
  min-width: max-content;
  position: relative;
  z-index: 1;
}

// Content
.ptr-MapConfigurationControl-content {
  padding: .5rem;
}

.ptr-MapConfigurationControl.is-right .ptr-MapConfigurationControl-content{
  left: auto;
  right: 0;
}