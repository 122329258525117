@import '../../../../styles/variables';

.unhab-FeaturedAreasLegend {
    width: 100%;
    margin-top: .5rem;
    display: flex;
    justify-content: center;
    grid-gap: .5rem;
}

.unhab-FeaturedAreasCountry {
    display: flex;
    grid-gap: .25rem;
    align-items: center;
}

.unhab-FeaturedAreasCountry-color {
    width: .7rem;
    height: .7rem;
    border-radius: 100%;
    opacity: .5;
}

.unhab-FeaturedAreasCountry-name {
    font-size: $b1;
    color: $textColor_secondary;
}