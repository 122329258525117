@use 'sass:math';
@import '../../../../../styles/variables.scss';

.unhab-XCubePointsControl {
  margin-top: .25rem;
  display: flex;
  flex-wrap: wrap;
  grid-gap: .5rem;
}

.unhab-XCubePointsControl-controlName {
  font-size: $b1;
  color: $textColor_base;
  line-height: 1;
  display: inline-block;
}