@use 'sass:math';
@import '../../../styles/variables';

.unhab-DataFilterControl-content {
  display: flex;
  grid-gap: 1rem;
  align-items: center;
}

.unhab-DataFilterControl-tagsContainer {
  display: flex;
  grid-gap: .15rem;
  align-items: center;
}

.unhab-DataFilterControl-tags {
  display: flex;
  grid-gap: .15rem;
  align-items: center;
  padding-top: .1rem;
}