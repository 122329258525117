@use 'sass:math';
@import '../../../../../styles/variables';

.ptr-MapConfigurationControlLabel {
  position: relative;
  z-index: 0;
  font-size: .75rem;
  line-height: 1;
  padding: .30rem .5rem .2rem .65rem;
  margin-left: -.15rem;
  font-style: italic;
  color: $textColor_base;
  background: rgba(var(--base0rgb), .8);
  display: flex;
  grid-gap: .5rem;
  border-radius: .1rem;
  @include frozenGlassEffect();
}

.ptr-MapConfigurationControlLabel-item {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.ptr-MapConfigurationControlLabel-item:not(:last-child) {
  padding-right: .5rem;
  border-right: 1px solid rgba(var(--base50rgb), .5);
}

.ptr-MapConfigurationControlLabel-item > span {
  font-size: .65rem;
  color: $textColor_secondary;
}

.ptr-MapConfigurationControlLabel.is-right {
  padding: .35rem .65rem .2rem .5rem;
  margin-right: -.15rem;
  margin-left: 0;
}