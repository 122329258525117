@use 'sass:math';
@import '../../../../../styles/variables';

.unhab-TimeProgressChart {
  position: relative;
}

.unhab-TimeProgressChart > div {
  position: relative;
}

.unhab-TimeProgressChart-attributeSwitch {
  margin-bottom: .25rem;
}

.unhab-TimeProgressChart .ptr-TimeRangeChartLegend {
  max-width: calc(100% - 7rem);
}

// Tooltip
.unhab-TimeProgressChart-tooltip-time {
  font-size: $b1;
  color: var(--base50);
  margin: 0;
}

.unhab-TimeProgressChart-tooltip-item {
  font-size: $a1;
  color: var(--base90);
  display: flex;
  align-items: center;
  flex-direction: row;
}

.unhab-TimeProgressChart-tooltip-color {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 0.35rem;
}