@use 'sass:math';
@import '../../../../styles/variables';

.ptr-BaseLayerControl-menu {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns with equal width */
  grid-gap: .75rem;
  margin: .25rem;
}

.ptr-BaseLayerControl-menu.one-row {
  display: flex;
}

.ptr-BaseLayerControlTile {
  width: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ptr-BaseLayerControlTile-thumbnail {
  border: 3px solid var(--base30);
  border-radius: .25rem;
}

.ptr-BaseLayerControlTile.active .ptr-BaseLayerControlTile-thumbnail {
  border-color: var(--accent50);
}

.ptr-BaseLayerControlTile-title {
  font-size: $b1;
  color: var(--base50);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-top: .1rem;
}

.ptr-BaseLayerControlTile.active .ptr-BaseLayerControlTile-title {
  font-weight: bold;
  color: var(--accent50);
}