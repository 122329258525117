@use 'sass:math';
@import '../../../../../styles/variables';

.unhab-ReportDescription {
    padding: .25rem 0 1rem;
}

.unhab-ReportDescription > textarea {
    font-size: $a1;
    color: $textColor_base;
}