@use 'sass:math';
@import '../../../../../../styles/variables';

.unhab-DataLayerControl {
	display: flex;
	flex-direction: column;
	grid-gap: 0.25rem;
	padding: 0.25rem 0.35rem;

	&.is-active:not(.loading) {
		background: var(--accent05);
		padding: 0.35rem;
		border-top: 1px solid var(--base05);
	}

	&:hover {
		background: $hoverBackgroundColor;
	}

	&.is-large {
		padding: 0.25rem 0.5rem;
	}

	&.is-large .unhab-DataLayerControl-header {
		font-size: $a1;
	}

	&.is-disabled {
		pointer-events: none;
	}

	&.is-disabled .unhab-DataLayerControl-name > span {
		color: $textColor_tertiary;
	}
}

.unhab-DataLayerControl-header {
	display: flex;
	font-size: $b1;
	align-items: center;
	grid-gap: 0.25rem;
	justify-content: start;
}

.unhab-DataLayerControl-name {
	display: flex;
	align-items: center;
	color: $textColor_base;
	min-width: 0;
}

.unhab-DataLayerControl-name > span {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.unhab-DataLayerControl-name > span > span {
	color: $textColor_secondary;
	font-size: 0.7rem;
}

.unhab-DataLayerControl.is-active:not(.loading) .unhab-DataLayerControl-name:not(.loading) > span {
	font-weight: bold;
	color: var(--accent50);
}

.unhab-DataLayerControl-tools {
	display: flex;
	align-items: center;
}

.unhab-DataLayerControl-tool,
.unhab-DataLayerControl-infoIcon {
	height: 1.4rem;
	width: 1.4rem;
	padding: .2rem;
	border-radius: .75rem;
}

.unhab-DataLayerControl-tools .ptr-Checkbox {
	margin: 0 .15rem 0 .25rem;
	cursor: pointer;
}

.unhab-DataLayerControl-tool {
	color: $iconColor_base;
	cursor: pointer;

	&.is-active {
		color: $iconColor_accented;
	}
}

.unhab-DataLayerControl-tool:hover {
	background: var(--base20);
}

.unhab-DataLayerControl-infoIcon > .ptr-react-icon,
.unhab-DataLayerControl-tool > .ptr-react-icon {
	width: 1rem;
	height: 1rem;
}

.unhab-DataLayerControl-tool.outlines {
	padding: .25rem;
}

.unhab-DataLayerControl-tool.outlines > .ptr-react-icon {
	width: .9rem;
	height: .9rem;
}

.unhab-DataLayerControl-infoIcon {
	color: var(--base40);
}

.unhab-DataLayerControl-tags {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;
}