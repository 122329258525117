@use 'sass:math';
@import '../../../../styles/variables';

.unhab-FilteredIndicatorsList {
	display: flex;
	flex-direction: column;
	margin-bottom: 0.5rem;
}

.unhab-FilteredIndicatorsList-group {
	margin-bottom: 0.5rem;
}

.unhab-FilteredIndicatorsList-groupTitle {
	margin: 0 0 0.25rem;
	font-size: 0.9rem;
}

.unhab-FilteredIndicatorsList-TitleGroup {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.unhab-FilteredIndicatorsList-groupInfo {
	margin-top: -3px;
}

.unhab-FilteredIndicatorsListItem {
	color: $textColor_base;
	display: flex;
	grid-gap: 0.5rem;
	font-size: $b1;
}

.unhab-FilteredIndicatorsListItem-icons {
	display: flex;
}

.unhab-FilteredIndicatorsList-wrapper .icon-placeholder,
.unhab-FilteredIndicatorsList-wrapper .ptr-react-icon,
.unhab-FilteredIndicatorsList-wrapper .ptr-icon {
	color: $iconColor_secondary;
	width: 1rem;
	height: 1rem;
}

.unhab-FilteredIndicatorsList-wrapper svg .line {
	fill: $iconColor_secondary;
}

.unhab-FilteredIndicatorsLegend {
	position: relative;
	padding-top: 1rem;
}

.unhab-FilteredIndicatorsLegend:before {
	content: '';
	width: 5rem;
	height: 1px;
	background: $borderColor_secondary;
	position: absolute;
	top: 0;
}

.unhab-FilteredIndicatorsLegend-item {
	display: flex;
	grid-gap: 0.35rem;
}

.unhab-FilteredIndicatorsLegend-name {
	color: $textColor_secondary;
	font-size: $b1;
	font-style: italic;
}
