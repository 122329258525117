@use 'sass:math';
@import '../../../styles/variables';

.unhab-LineChartTooltip {
    display: flex;
    grid-gap: .25rem;
    background: var(--base100);
    padding: .25rem .5rem;
    border-radius: .15rem;
    box-shadow: 0 3px 10px rgba(var(--base50rgb), 0.2);
    align-items: center;
}

.unhab-LineChartTooltip-color {
    width: .7rem;
    height: .7rem;
    border-radius: .5rem;
}

.unhab-LineChartTooltip-period {
    font-size: $b1;
    color: var(--base30);
    padding-top: .1rem;
}

.unhab-LineChartTooltip-value {
    font-size: $b1;
    font-weight: bold;
    color: var(--base10);
    padding-top: .1rem;
}