@use 'sass:math';
@import '../../../../../../styles/index.scss';

.unhab-Attribute-absolute {
	display: flex;
	grid-gap: .15rem;
	align-items: baseline;
}

.unhab-Attribute-absolute > div {
	font-weight: bold;
}

.unhab-Attribute-absolute > span {
	font-size: $b1;
	color: $textColor_secondary;
}

.unhab-Attribute-relative {
	display: flex;
	grid-gap: .15rem;
	align-items: baseline;
}

.unhab-Attribute-relative > div {
	font-weight: bold;
}

.unhab-Attribute-relative > span {
	font-size: $b1;
	color: $textColor_secondary;
}
