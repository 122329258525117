@import '~@gisatcz/ptr-core/lib/styles/_variables';
@import '~@gisatcz/ptr-maps';
@import '~@gisatcz/ptr-components';
@import '~@gisatcz/ptr-atoms';
@import '~@gisatcz/ptr-core';
@import './variables';

.ptr-light {
	--base0: #{$lightBase0};
	--base0rgb: #{$lightBase0rgb};
	--base05: #{$lightBase05};
	--base10: #{$lightBase10};
	--base15: #{$lightBase15};
	--base20: #{$lightBase20};
	--base25: #{$lightBase25};
	--base30: #{$lightBase30};
	--base35: #{$lightBase35};
	--base40: #{$lightBase40};
	--base45: #{$lightBase45};
	--base50: #{$lightBase50};
	--base50rgb: #{$lightBase50rgb};
	--base55: #{$lightBase55};
	--base60: #{$lightBase60};
	--base65: #{$lightBase65};
	--base70: #{$lightBase70};
	--base75: #{$lightBase75};
	--base80: #{$lightBase80};
	--base85: #{$lightBase85};
	--base90: #{$lightBase90};
	--base95: #{$lightBase95};
	--base100: #{$lightBase100};
	--base100rgb: #{$lightBase100rgb};

	--accent0: #{$lightAccent0};
	--accent05: #{$lightAccent05};
	--accent10: #{$lightAccent10};
	--accent15: #{$lightAccent15};
	--accent20: #{$lightAccent20};
	--accent25: #{$lightAccent25};
	--accent30: #{$lightAccent30};
	--accent35: #{$lightAccent35};
	--accent40: #{$lightAccent40};
	--accent45: #{$lightAccent45};
	--accent50: #{$lightAccent50};
	--accent50rgb: #{$lightAccent50rgb};
	--accent55: #{$lightAccent55};
	--accent60: #{$lightAccent60};
	--accent65: #{$lightAccent65};
	--accent70: #{$lightAccent70};
	--accent75: #{$lightAccent75};
	--accent80: #{$lightAccent80};
	--accent85: #{$lightAccent85};
	--accent90: #{$lightAccent90};
	--accent95: #{$lightAccent95};
	--accent100: #{$lightAccent100};
}

.ptr-dark {
	--base0: #{$darkBase0};
	--base0rgb: #{$darkBase0rgb};
	--base05: #{$darkBase05};
	--base10: #{$darkBase10};
	--base15: #{$darkBase15};
	--base20: #{$darkBase20};
	--base25: #{$darkBase25};
	--base30: #{$darkBase30};
	--base35: #{$darkBase35};
	--base40: #{$darkBase40};
	--base45: #{$darkBase45};
	--base50: #{$darkBase50};
	--base50rgb: #{$darkBase50rgb};
	--base55: #{$darkBase55};
	--base60: #{$darkBase60};
	--base65: #{$darkBase65};
	--base70: #{$darkBase70};
	--base75: #{$darkBase75};
	--base80: #{$darkBase80};
	--base85: #{$darkBase85};
	--base90: #{$darkBase90};
	--base95: #{$darkBase95};
	--base100: #{$darkBase100};
	--base100rgb: #{$darkBase100rgb};

	--accent0: #{$darkAccent0};
	--accent05: #{$darkAccent05};
	--accent10: #{$darkAccent10};
	--accent15: #{$darkAccent15};
	--accent20: #{$darkAccent20};
	--accent25: #{$darkAccent25};
	--accent30: #{$darkAccent30};
	--accent35: #{$darkAccent35};
	--accent40: #{$darkAccent40};
	--accent45: #{$darkAccent45};
	--accent50: #{$darkAccent50};
	--accent50rgb: #{$darkAccent50rgb};
	--accent55: #{$darkAccent55};
	--accent60: #{$darkAccent60};
	--accent65: #{$darkAccent65};
	--accent70: #{$darkAccent70};
	--accent75: #{$darkAccent75};
	--accent80: #{$darkAccent80};
	--accent85: #{$darkAccent85};
	--accent90: #{$darkAccent90};
	--accent95: #{$darkAccent95};
	--accent100: #{$darkAccent100};
}

// custom scrollbar
/* width */
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--base40);
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: var(--base50);
}


// Buttons
.esaUtepUnHabitat .ptr-button:not(.primary) {
	color: $iconColor_base;
}

.esaUtepUnHabitat .ptr-button.disabled {
	color: $iconColor_disabled;
}

.esaUtepUnHabitat .ptr-button.circular.disabled {
	background: none;
}

.esaUtepUnHabitat .ptr-button.circular {
	top: 0;
	left: 0;
}

.esaUtepUnHabitat .ptr-button.ghost,
.ReactModalPortal .ptr-button.ghost {
	border-color: $borderColor_base;
	color: $textColor_base;
}

.esaUtepUnHabitat .ptr-button.ghost.disabled {
	border-color: $textColor_disabled;
	color: $textColor_disabled;
}

.esaUtepUnHabitat .ptr-button-switch .ptr-button.ghost.active,
.ReactModalPortal .ptr-button-switch .ptr-button.ghost.active {
	background: var(--accent10);
	color: var(--accent60);
	border-color: $borderColor_accented;
}

.esaUtepUnHabitat .ptr-button-switch .ptr-button.ghost.active .ptr-button-caption,
.ReactModalPortal .ptr-button-switch .ptr-button.ghost.active .ptr-button-caption {
	font-weight: bold;
}
