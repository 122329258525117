@use 'sass:math';
@import '../../../styles/variables';

.unhab-DatasetInfo-container {
	display: flex;
	justify-content: center;
	margin-left: 5px;
	height: 1.04rem;
	width: 1.04rem;
	cursor: pointer;
	background: var(--base10);
	border-radius: 0.4rem;

	svg {
		height: 1.04rem;
		width: 1.04rem;
		border-radius: 1.04rem;
		transition: 0.2s ease-in-out;
		color: var(--base60) !important;
	}

	svg:hover {
		background: var(--base20);
	}
}

.info-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.info-modal-content {
	padding: 20px;
	border-radius: 8px;
	max-width: 600px;
	width: 100%;
	box-shadow: $floatingElementShadow;
	position: relative;
}

.info-modal-content h2 {
	margin-top: 0;
	font-size: 24px;
}

.info-modal-content .section {
	margin-bottom: 20px;

	h3 {
		margin-bottom: 10px;
		font-size: 20px;
	}

	h4 {
		margin-bottom: 5px;
		font-size: 18px;
	}

	p {
		margin-bottom: 10px;
		font-size: 16px;
	}

	ul {
		list-style-type: disc;
		padding-left: 20px;
	}
}

.unhab-DatasetInfo-image {
	max-width: 100%;
	height: auto;
	margin-top: 10px;
	border-radius: 4px;
}

.brief-description-link {
	margin-bottom: 20px;
	display: block;
}

.info-modal-content button {
	margin-top: 20px;
	padding: 10px 20px;
	font-size: 16px;
	color: var(--base0);
	background-color: var(--accent50);
	border: none;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s;

	&:hover {
		background-color: var(--accent50);
	}
}
