@use 'sass:math';
@import '../../../../styles/variables';

.unhab-ReportPreview {
  flex: 1 1;
  height: 100%;
  background: var(--base20);
  padding: 1rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.unhab-ReportPages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
}

.unhab-ReportPreview.is-print-in-progress .unhab-ReportPages {
  grid-gap: 0;
}

.unhab-ReportPage {
  width: $reportPageWidth;
  height: $reportPageHeight;
  margin: 0 auto;
  background: var(--base0);
  box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
  padding: $reportPagePadding;
}

.unhab-ReportPage:empty {
  display: none;
}

.unhab-ReportPreview-section-header {
  margin: 0;
  padding: 1.5rem 0 .5rem;
  font-size: 1.2rem;
}

.unhab-ReportPreview-prerender {
  width: $reportPageWidth;
  margin: 0 auto;
  padding: $reportPagePadding;
  visibility: hidden;
}

.unhab-ReportPage:has(.ptr-IndicatorBoxSet:empty):not(:has(.unhab-Chart)) h3 {
  display: none;
}

.unhab-ReportPage:last-child .unhab-ReportPreview-section-header:last-child {
  display: none;
}

.ptr-button.unhab-ReportPreview-GenerateButton {
  margin: 1rem auto;
  position: fixed;
  z-index: 99;
  bottom: 0;
  box-shadow: 0 5px 10px rgba(0,0,0,.35);
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
  }
  .unhab-ReportPage {
    break-after: page;
  }

  .unhab-ReportSidePanel, .unhab-Header {
    display: none;
  }

  .unhab-ReportPreview {
    padding: 0;
    height: auto;
    display: block;
  }

  .ptr-button.unhab-ReportPreview-GenerateButton {
    display: none;
  }

  .unhab-ReportPreview-prerender {
    display: none;
  }

  #ptr-app {
    position: relative !important;
  }
}