@use 'sass:math';
@import '../../../../styles/variables';

.unhab-MapExportModal {
    max-width: 95%;
    max-height: 95%;
}

.unhab-MapExportModal .ptr-Modal-content {
    max-height: 100%;
}

.unhab-MapExportControl-button {
    &.is-hidden {
        display: none;
    }
}