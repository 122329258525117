@use 'sass:math';
@import '../../../../../../styles/variables';

.unhab-LayersGroupControl-title {
	display: flex;
	gap: 0.3rem;
	font-weight: bold;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: flex;
	flex-direction: row;
	cursor: pointer;
}

.unhab-LayersGroupControl-checkbox {
	display: flex;
	align-items: center;
}