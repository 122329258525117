@use 'sass:math';
@import '../../../styles/variables';

.unhab-SelectedFeaturesControl-note {
	font-size: $b1;
	line-height: 24px;
	color: $textColor_secondary;
	width: 100%;

	> div {
		height: 24px;
	}
}
