@use 'sass:math';
@import '../../../../styles/variables';

$logoWidth: 10rem;

// Logo
.unhab-Header-logo {
	width: 100%;
	height: $headerHeight;
	display: flex;
	align-items: center;
	background: var(--accent50);
}

.unhab-Header-logo svg {
	width: calc(100px - 2 * $mapWindowPadding_right);
	height: $headerHeight;
	padding: 5px;
}

.unhab-Header-logo > * {
	fill: #ffffff;
}
