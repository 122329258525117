@use 'sass:math';
@import '../../../../styles/variables';

.unhab-DataLayersControl {
  display: flex;
  flex-direction: column;
  grid-gap: .25rem;
  width: 23rem;
  max-height: 25rem;
  //overflow-y: auto;
}