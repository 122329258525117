@use 'sass:math';
@import '../../../styles/variables';

.ptr-Box.unhab-Chart {
  background: var(--base0);
  border-radius: .15rem;
  padding: 0;
  margin-top: -1px;

  &.is-report {
    border-radius: 0;
    border: 1px solid var(--base20);
    max-width: $reportColumnWidth;
  }

  &.is-report .unhab-TimeProgressChart {
    max-height: 10rem;
  }

  &.is-collapsed {
    max-height: 2.25rem;
    overflow: hidden;

    .unhab-Chart-header {
      padding: 0.25rem 0.25rem 0 0.5rem;
    }

    .ptr-BoxSubtitle {
      display: none;
    }

    .unhab-DownloadChart {
      display: none;
    }
  }
}

.unhab-Chart-title {
  display: flex;
  align-items: center;
  grid-gap: .25rem;
}

.unhab-Chart-header {
  display: flex;
  justify-content: space-between;
  padding: .5rem .25rem 0 .5rem;
}

.unhab-Chart-tools {
  display: flex;
}

.unhab-Chart-body {
  padding: .5rem;
}