@use 'sass:math';
@import '../../../../styles/variables';

.unhab-XCubePanel {
  width: 40%;
  min-width: 20rem;
  max-width: 35rem;
}

.unhab-XCubePanel h3 {
  margin: 0;
}

.xCube-ProgressChart {
  display: flex;
  flex-direction: column;
  grid-gap: .5rem;
}