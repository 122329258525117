@use 'sass:math';
@import '../../../styles/variables';

.visatSdg-HeaderSelect {
  margin: .1rem -.5rem 0;
}

.visatSdg-HeaderSelect svg {
  width: 14px;
}

.visatSdg-HeaderSelect-CustomValue {
  display: flex;
  align-items: center;
  grid-gap: .35rem;
}

.visatSdg-HeaderSelect-CustomValue > svg {
  width: 1rem;
}

.visatSdg-HeaderSelect-CustomValue .ptr-icon .line {
  fill: currentColor;
}

.visatSdg-HeaderSelect-CustomValue > span {
  font-weight: 600;
}