@use 'sass:math';
@import '../../../styles/variables';

.unhab-PeriodSelector {
  display: flex;
  grid-gap: .25rem;
  width: 20rem;
  align-items: center;

  &.is-narrow {
    width: 10rem;
  }

  &.is-simple-view {
    width: auto;
  }

}

.unhab-PeriodSelector > span {
  font-size: $b1;
  color: $textColor_secondary;
}

.unhab-PeriodSelector > p {
  margin: 0;
  font-size: $b1;
  font-style: italic;
}

.unhab-PeriodSelector-slider {
  height: .1rem;
  border-radius: 5rem;
  flex: 1;
  margin: 0 .5rem;
}

.unhab-PeriodSelector-thumb, {
  margin-top: -0.6rem;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .75rem;
  cursor: pointer;
  outline: none;
}

.unhab-PeriodSelector-thumb:hover {
  background: var(--accent40);
  outline: none;
}

.unhab-PeriodSelector-thumb-0,
.unhab-PeriodSelector-thumb-0:hover,
.unhab-PeriodSelector-thumb-1,
.unhab-PeriodSelector-thumb-1:hover{
  background: transparent;
}
.unhab-PeriodSelector-thumb-0 > div,
.unhab-PeriodSelector-thumb-1 > div {
  position: absolute;
  color: var(--accent60);
  background: var(--accent10);
  width: 1.35rem;
  height: 1.35rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .75rem;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid var(--accent50);
}

.unhab-PeriodSelector-thumb-0 > div {
  left: -.55rem;
  padding-top: 1px;
}

.unhab-PeriodSelector-thumb-1 > div {
  right: -.55rem;
  padding-top: 1px;
}

.unhab-PeriodSelector-thumb-0 > div:hover,
.unhab-PeriodSelector-thumb-1 > div:hover {
  background: var(--accent15);
}

.unhab-PeriodSelector-track {
  top: 0;
  bottom: 0;
  border-radius: 999px;
}

.unhab-PeriodSelector-track-0 {
  background: var(--base30);
}

.unhab-PeriodSelector-track-1 {
  background: $iconColor_accented;
  z-index: 1;
}

.unhab-PeriodSelector-track-2 {
  background: var(--base30);
}
