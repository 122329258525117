@use 'sass:math';
@import '../../../styles/variables';

.unhab-AnalyticsPanelStatistics {
  display: flex;
  flex-direction: column;
  //background: var(--accent05);
  padding: 0 $sidePanel_padding;
  margin: 0 (-$sidePanel_padding);
}

.unhab-AnalyticsPanelStatistics-title {
  margin: 0 0 .5rem 0;
}

.unhab-AnalyticsPanelStatistics-body {
  display: flex;
  flex-direction: column;
  grid-gap: .75rem;
}