@use 'sass:math';
@import '../../../styles/variables';

.unhab-MapExportDialog {
    height: 100%;
    margin: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.unhab-MapExportDialog > h2 {
    margin: 0 0 1.5rem;
}

.unhab-MapExportDialog-tools {
    margin-bottom: 1rem;
}

.unhab-MapExportDialog-mapWindow {
    overflow-y: auto;
    border: 2px solid var(--base90);
    position: relative;
}