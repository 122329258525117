@use 'sass:math';
@import '../../../../../../styles/variables';

.unhab-SortLayersItem {
	font-size: $b1;
	color: $textColor_base;
	align-items: center;
}

.unhab-SortLayersItem a {
	color: $iconColor_base;
	height: 1.15rem;
	margin-right: 0.05rem;
	margin-left: -0.25rem;

	&:hover {
		cursor: grab;
		color: $iconColor_base;
	}
}

.unhab-SortLayersItem .unhab-ItemName {
	flex-grow: 1;
	display: flex;
	flex-wrap: wrap;
	padding-top: 0.25rem;
	align-items: baseline;
}

.unhab-SortLayersItem .unhab-ItemName > span:first-child {
	margin-right: .15rem;
}

.unhab-SortLayersItem .unhab-ItemName > span:last-child {
	font-size: $a0;
	color: $textColor_secondary;
}

.unhab-ItemName-tags {
	display: flex;
	flex-direction: row;
	gap: 0.2rem;
	margin-left: 0.1rem;
	align-items: center;
}